import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link as ScrollLink } from "react-scroll"
import { Link } from "gatsby"
//import { SlideDown } from "react-slidedown"

import styles from "src/css/fp-new.module.css"
import urls from "src/content/urls"
import { changeMenuH } from "src/store/reducers/sizes"

import smallArrow from "src/images/smallArrow.svg"
import smallArrowBlack from "src/images/smallArrowBlack.svg"


class TopMenu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      opened: false,
    }

    this.arrow = this.props.black ? smallArrowBlack : smallArrow

    this.menuObj = React.createRef()
    this.menuBgObj = React.createRef()
    this.dropdownObj = React.createRef()

    this.dropdownChange = this.dropdownChange.bind(this)
  }

  resizeEvent() {
    if (this.menuObj.current !== null) {
      this.props.dispatch(changeMenuH(this.menuObj.current.clientHeight))
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeEvent.bind(this))
    window.addEventListener("load", this.resizeEvent.bind(this))
    this.resizeEvent()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEvent.bind(this))
    window.removeEventListener("load", this.resizeEvent.bind(this))
  }

  dropdownChange() {
    this.setState({...this.state, opened: !this.state.opened})

    if (this.dropdownObj.current != null) {
      let dropdown = this.dropdownObj.current

      if (dropdown.classList.contains(styles.opened)) {

      } else {
        dropdown.classList.add(styles.opened)
      }
    }
  }

  render() {
    if (this.menuBgObj.current != null) {
      let menuBgObj = this.menuBgObj.current
      if (this.props.scrollPos.scrollPos <= 10) {
        if (menuBgObj.classList.contains(styles.shown)) {
          menuBgObj.classList.remove(styles.shown)
          menuBgObj.classList.add(styles.hidden)
        }
      } else {
        if (menuBgObj.classList.contains(styles.hidden)) {
          menuBgObj.classList.remove(styles.hidden)
          menuBgObj.classList.add(styles.shown)
        }
      }
    }

    let classes = [styles.fp_menu]
    if (this.props.black) classes.push(styles.black)

    return (
      <div className={classes.join(" ")} ref={this.menuObj} style={this.props.style}>
        <div className={styles.menu_content_wrapper}>
          <div className={[styles.menu_bg, styles.hidden].join(" ")} ref={this.menuBgObj}/>
          <div className={styles.menu_content}>
            {this.props.homeBack ? (
              <div className={[styles.menu_item, styles.back_home_item].join(" ")}>
                <div className={styles.box}>
                  <img src={this.arrow}/>
                  <Link to={urls.pl.index} className={styles.text}>Strona&nbsp;głowna</Link>
                </div>
              </div>
            ) : (null)}
            <div className={[styles.menu_item, styles.dropdown].join(" ")} onClick={this.dropdownChange}
                 ref={this.dropdownObj}>
              <div className={styles.box}>
                <img src={this.arrow}/>
                <div className={styles.text}>Nasze&nbsp;specjalności</div>
              </div>
              <div className={styles.submenu}>
                <div className={styles.submenu_title}>Nasze specjalności</div>
                <div className={styles.submenu_section}>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.branding} activeClassName="active">Stworzenie marki</Link>
                  </div>
                </div>
                <div className={styles.submenu_section}>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.gadgets} activeClassName="active">Gadżety</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.papers} activeClassName="active">Ulotki / Wizytówki</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.outside} activeClassName="active">Reklama zewnętrzna</Link>
                  </div>
                </div>
                <div className={styles.submenu_section}>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.marketing} activeClassName="active">Kampanie reklamowe</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.websites} activeClassName="active">Strony WWW</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.socialmedia} activeClassName="active">Opieka Social Media</Link>
                  </div>
                </div>
                <div className={styles.submenu_section}>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.wideo} activeClassName="active">Wideo</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.wrapping} activeClassName="active">Oznakowanie pojazdów</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.printing} activeClassName="active">Wydruki wielkoformatowe</Link>
                  </div>
                </div>
                <div className={styles.submenu_section}>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.it} activeClassName="active">Zaawansowane strony WWW</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.it} activeClassName="active">Systemy firmowe</Link>
                  </div>
                  <div className={styles.submenu_item}>
                    <Link to={urls.pl.it} activeClassName="active">Aplikacje mobilne</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.menu_item}>
              <ScrollLink to="fpFooter" smooth={true} duration={500}>Kontakt</ScrollLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

TopMenu.propTypes = {
  homeBack: PropTypes.bool,
  black: PropTypes.bool,
  style: PropTypes.object,
}

TopMenu.defaultProps = {
  homeBack: false,
  black: false,
  style: {},
}

export default connect(state => ({
  menuH: state.menuH,
  scrollPos: state.scrollPos,
}), null)(TopMenu)