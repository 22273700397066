import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactVivus from "react-vivus"
import Vivus from "vivus";
import ReactSVG from "react-svg"
import { CSSTransition } from "react-transition-group"

import gradStyles from "src/css/gradients.module.css"


export default class Gradients extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      grad2_default_active: true,
      grad2_02_active: false,
      grad2_03_active: false,
      grad2_04_active: false,
      grad2_05_active: false,
    }
    //this.nextState =

      this.activeGrad = this.activeGrad.bind(this)
  }

  activeGrad(grad) {
    let defaultState = {
      grad2_default_active: false,
      grad2_02_active: false,
      grad2_03_active: false,
      grad2_04_active: false,
      grad2_05_active: false,
    }
    switch (grad) {
      case 1:
      case "default":
        defaultState.grad2_default_active = true
        break
      case 2:
        defaultState.grad2_02_active = true
        break
      case 3:
        defaultState.grad2_03_active = true
        break
      case 4:
        defaultState.grad2_04_active = true
        break
      case 5:
        defaultState.grad2_05_active = true
        break
    }
    this.setState(defaultState)
  }

  componentWillMount() {
    this.activeGrad(this.props.activeGradient)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.activeGrad(nextProps.activeGradient)
  }

  componentDidMount() {
    new Vivus("bgIcon2", {
      file: this.props.iconBg,
      type: "delayed",
      animTimingFunction: Vivus.EASE,
      duration: 100,
      start: "autostart",
    }, () => {
      console.log("done anim")
    })
  }

  render() {
    let classnames = {
      appear: gradStyles.grad_enter,
      appearActive: gradStyles.grad_enter_active,
      appearDone: gradStyles.grad_enter_done,
      enter: gradStyles.grad_enter,
      enterActive: gradStyles.grad_enter_active,
      enterDone: gradStyles.grad_enter_done,
      exit: gradStyles.grad_exit,
      exitActive: gradStyles.grad_exit_active,
      exitDone: gradStyles.grad_exit_done,
    }

    return (
      <div className={gradStyles.grads}>
        <div className={gradStyles.grad1}/>

        <CSSTransition in={this.state.grad2_default_active}
                       appear={true}
                       timeout={1500}
                       classNames={classnames}>
          <div className={[gradStyles.grad2, gradStyles.grad2_stage1].join(" ")}/>
        </CSSTransition>
        <CSSTransition in={this.state.grad2_02_active}
                       appear={true}
                       timeout={1500}
                       classNames={classnames}>
          <div className={[gradStyles.grad2, gradStyles.grad2_stage2].join(" ")}/>
        </CSSTransition>
        <CSSTransition in={this.state.grad2_03_active}
                       appear={true}
                       timeout={1500}
                       classNames={classnames}>
          <div className={[gradStyles.grad2, gradStyles.grad2_stage3].join(" ")}/>
        </CSSTransition>
        <CSSTransition in={this.state.grad2_04_active}
                       appear={true}
                       timeout={1500}
                       classNames={classnames}>
          <div className={[gradStyles.grad2, gradStyles.grad2_stage4].join(" ")}/>
        </CSSTransition>
        <CSSTransition in={this.state.grad2_05_active}
                       appear={true}
                       timeout={1500}
                       classNames={classnames}>
          <div className={[gradStyles.grad2, gradStyles.grad2_stage5].join(" ")}/>
        </CSSTransition>
        {this.props.iconBg !== undefined ? (
          <div className={gradStyles.grad_bg_icon}>
            <div id="bgIcon2" />
            {/*<ReactVivus*/}
            {/*  id="bgIcon2"*/}
            {/*  option={{*/}
            {/*    file: this.props.iconBg,*/}
            {/*    type: "delayed",*/}
            {/*    animTimingFunction: "EASE",*/}
            {/*    duration: 100,*/}
            {/*    start: "autostart",*/}
            {/*  }}*/}
            {/*/>*/}
          </div>
        ) : null}
      </div>
    )
  }
}

Gradients.propTypes = {
  activeGradient: PropTypes.number,
  iconBg: PropTypes.string,
}

Gradients.defaultProps = {
  activeGradient: undefined,
  iconBg: undefined,
}