import urls from "src/content/urls"


import fingerprint from "src/images/icons_sizes/fingerprint.svg"
import tshirt from "src/images/icons_sizes/gadgets.svg"
import ulotki from "src/images/icons_sizes/papers.svg"
import store from "src/images/icons_sizes/outdoor.svg"
import website from "src/images/icons_sizes/website.svg"
import marketing from "src/images/icons_sizes/marketing.svg"
import socialmedia from "src/images/icons_sizes/socialmedia.svg"
import movies from "src/images/icons_sizes/video.svg"
import wrapping from "src/images/icons_sizes/wrapping.svg"
import printers from "src/images/icons_sizes/big_size.svg"
import it from "src/images/icons_sizes/it.svg"
import website_mobile from "src/images/icons_sizes/website_mobile.svg"


export default {
  branding: {
    id: "branding",
    icon: fingerprint,
    title: {
      pl: "Stworzenie Marki"
    },
    link: urls.pl.branding,
  },
  papers: {
    id: "papers",
    icon: ulotki,
    title: {
      pl: "Ulotki / wizytówki"
    },
    link: urls.pl.papers,
  },
  gadgets: {
    id: "gadgets",
    icon: tshirt,
    title: {
      pl: "Gadżety"
    },
    link: urls.pl.gadgets,
  },
  outdoor: {
    id: "outdoor",
    icon: store,
    title: {
      pl: "Reklama zewnętrzna"
    },
    link: urls.pl.outside,
  },
  marketing: {
    id: "marketing",
    icon: marketing,
    title: {
      pl: "Kampanie reklamowe"
    },
    link: urls.pl.marketing,
  },
  website: {
    id: "website",
    icon: website,
    title: {
      pl: "Strony WWW"
    },
    link: urls.pl.websites,
  },
  socialmedia: {
    id: "socialmedia",
    icon: socialmedia,
    title: {
      pl: "Opieka Social Media"
    },
    link: urls.pl.socialmedia,
  },
  video: {
    id: "video",
    icon: movies,
    title: {
      pl: "Wideo"
    },
    link: urls.pl.wideo,
  },
  wrapping: {
    id: "wrapping",
    icon: wrapping,
    title: {
      pl: "Oznakowanie pojazdów"
    },
    link: urls.pl.wrapping,
  },
  printers: {
    id: "printers",
    icon: printers,
    title: {
      pl: "Wydruki wielkoformatowe"
    },
    link: urls.pl.printing,
  },
  it: {
    id: "it",
    icon: it,
    title: {
      pl: "Zaawansowane Strony WWW\nSystemy Firmowe\nAplikacje Mobilne"
    },
    link: urls.pl.it,
  },
  website_mobile: {
    id: "website_mobile",
    icon: website_mobile,
    title: {
      pl: "Strony WWW"
    },
    link: urls.pl.websites,
  },
}